exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about_us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blogs-essential-tips-for-caring-js": () => import("./../../../src/pages/blogs/essential-tips-for-caring.js" /* webpackChunkName: "component---src-pages-blogs-essential-tips-for-caring-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-blogs-the-vital-role-of-home-caregivers-js": () => import("./../../../src/pages/blogs/the-vital-role-of-home-caregivers.js" /* webpackChunkName: "component---src-pages-blogs-the-vital-role-of-home-caregivers-js" */),
  "component---src-pages-blogs-understanding-alzheimer-js": () => import("./../../../src/pages/blogs/understanding-alzheimer.js" /* webpackChunkName: "component---src-pages-blogs-understanding-alzheimer-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact_us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

